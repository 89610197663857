import {
  Avatar,
  Button,
  Dialog,
  DownloadButtonWithHref,
  FlexSpacedColumn,
  LegacyDialog,
  Text,
  colorTokens,
  desktopMediaQuery,
  spacing,
  useLanguage,
  useTranslate,
  useUiContext,
} from '@orus.eu/pharaoh'
import { getMyCertificateDownloadLink } from '../../../lib/download-links'

import styled from '@emotion/styled'
import type { CustomerContractDescription } from '@orus.eu/backend/src/views/customer-contract-view'

import { memo } from 'react'
import { dynamicFileNames } from '../../../lib/download-filenames'

type WelcomeDialogProps = {
  onClose: () => void
  contracts: CustomerContractDescription[]
  customerEmail: string
}
export const WelcomeDialog = memo<WelcomeDialogProps>(function WelcomeDialog({ onClose, contracts, customerEmail }) {
  return contracts.length === 1 ? (
    <FirstTimeWelcomeDialog onClose={onClose} contract={contracts[0]} customerEmail={customerEmail} />
  ) : (
    <SubsequentWelcomeDialog onClose={onClose} />
  )
})

type FirstTimeWelcomeDialogProps = {
  onClose: () => void
  contract: CustomerContractDescription
  customerEmail: string
}

const FirstTimeWelcomeDialog = memo<FirstTimeWelcomeDialogProps>(function WelcomeDialog({
  onClose,
  contract,
  customerEmail,
}) {
  const translate = useTranslate()
  const language = useLanguage()
  const downloadCertificateLink = getMyCertificateDownloadLink(contract.subscriptionId)
  const screenType = useUiContext()

  return (
    <Dialog
      onClose={onClose}
      size="medium"
      title={translate('welcome_to_orus')}
      primaryActionLabel={translate('open_my_personal_space')}
      onPrimaryAction={onClose}
    >
      <StyledContentContainer>
        <Avatar illustration="welcome_big" size="120" css={{ margin: `${spacing[50]} 0` }} />
        <StyledTextAndButtonContainer>
          <StyledTextContainer>
            <Text variant="body2Medium">{translate('contract_validated')}</Text>
            <Text variant="body2" color={colorTokens['color-text-base-basic']}>
              {translate('contract_validated_explanation')}
            </Text>
          </StyledTextContainer>
          <DownloadButtonWithHref
            variant="secondary"
            size={screenType === 'mobile' ? 'large' : 'medium'}
            icon="arrow-down-to-line-regular"
            iconColor={colorTokens['color-fg-base']}
            href={downloadCertificateLink}
            fullWidth={screenType === 'mobile'}
            fileName={dynamicFileNames['insurance-certificate'](customerEmail, language)}
            displayName={screenType === 'mobile' ? translate('download_my_certificate') : translate('download')}
          />
        </StyledTextAndButtonContainer>
        <StyledTextContainer>
          <Text variant="body2Medium">{translate('all_in_one_space')}</Text>
          <Text variant="body2">{translate('all_in_one_space_explanation')}</Text>
        </StyledTextContainer>
      </StyledContentContainer>
    </Dialog>
  )
})

const SubsequentWelcomeDialog = memo<{ onClose: () => void }>(function SubsequentWelcomeDialog({ onClose }) {
  const translate = useTranslate()
  return (
    <LegacyDialog
      header={
        <TitleRow>
          <Avatar size="50" icon="circle-check-regular" color={colorTokens['color-bg-success-primary']} />
          <Text variant="h6">{translate('new_contract_signed')}</Text>
        </TitleRow>
      }
      onClose={onClose}
    >
      <Container>
        <Text variant="body2Medium">{translate('new_contract_signed_thank_you')}</Text>
        <Text variant="body2">{translate('new_contract_signed_explanation')}</Text>
        <FlexPushRight>
          <Button variant="primary" icon="arrow-right-regular" onClick={onClose}>
            {translate('continue')}
          </Button>
        </FlexPushRight>
      </Container>
    </LegacyDialog>
  )
})

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing[40]};
`

const Container = styled(FlexSpacedColumn)`
  padding: ${spacing[40]} 0;
  gap: ${spacing[40]};
  padding-bottom: 0;
`

const FlexPushRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const StyledContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${spacing[70]};
`

const StyledTextAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing[50]};

  ${desktopMediaQuery} {
    flex-direction: row;
  }
`

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[30]};
`
